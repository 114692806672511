export function useValidate() {
  const validateRefs = (refs: any[]) => {
    const data = refs.map((item) => {
      if (!item) {
        return true
      } else {
        const data = item.value?.validate()
        return data !== undefined
      }
    })
    return data.every((item) => !!item)
  }
  const resetRefs = (refs: any[]) => {
    const data = refs.map((item) => {
      if (!item || !item.value) {
        return true
      } else {
        const data = item.value.resetValidate()
        return data !== undefined
      }
    })
    return data.every((item) => !!item)
  }

  return {
    validateRefs,
    resetRefs
  }
}
